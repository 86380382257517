<template>
	<div class="container-fluid fadein">
		<div class="row g-0 text-center">
			<div class="link-box col-12 col-md-6 col-xl-3">
				<div class="link-box-inner">
					<div class="box">
						<a href="https://jungfraualetsch.ch/" title="jungfraualetsch.ch" class="box-inner" style="background-image: url('/img/bg/blue.png');">
							<div class="box-content">
								<img src="img/logos/saja.svg" class="logo">
								<h2 v-html='$t("World Heritage Organization")'></h2>
							</div>
						</a>
					</div>
				</div>
			</div>
			<div class="link-box col-12 col-md-6 col-xl-3">
				<div class="link-box-inner">
					<div class="box">
						<a href="https://wnf.ch/" title="wnf.ch" class="box-inner" style="background-image: url('/img/bg/green.png');">
							<div class="box-content">
								<img src="img/logos/wnf.svg" class="logo">
								<h2 v-html='$t("World Heritage Visitor Center")'></h2>
							</div>
						</a>
					</div>
				</div>
			</div>
			<div class="link-box col-12 col-md-6 col-xl-3">
				<div class="link-box-inner">
					<div class="box">
						<a href="https://ourheritage.ch/schweizer-alpen-jungfrau-aletsch" title="ourheritage.ch" class="box-inner" style="background-image: url('/img/bg/red.png');">
							<div class="box-content">
								<img src="img/logos/ourheritage.svg" class="logo">
								<h2 v-html='$t("World Heritage Experience")'></h2>
							</div>
						</a>
					</div>
				</div>
			</div>
			<div class="link-box col-12 col-md-6 col-xl-3">
				<div class="link-box-inner">
					<div class="box">
						<a href="https://visit.wnf.ch" title="visit.wnf.ch" class="box-inner" style="background-image: url('/img/bg/yellow.png');">
							<div class="box-content">
								<img src="img/logos/virtual.svg" class="logo">
								<h2 v-html='$t("World Heritage virtual")'></h2>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'App'
}
</script>
